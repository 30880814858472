.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & {
    img {
      display: block;
      width: 120px;
      height: 100%;
      object-fit: contain;
    }
  }
}
.cmp-page-size-select {
  display: flex;
  align-items: center;
  & > p {
    color: #00000099;
    font-weight: 400;
    font-size: 14px;
  }
}
