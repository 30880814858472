@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.add-session-logs-template {
  &-page-container {
    & .cmp-sticky-page-header {
      top: 64px;
      width: flex;
      background-color: #ddf3f1 !important;
      box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 10%) !important;
    }
  }
  &-header {
    &-container {
      padding: 20px 32px;

      & h3 {
        @include mobileScreen {
          display: none;
        }
      }
    }
    &-status {
      padding: 10px 32px;
      &-left {
        & > * {
          margin-right: 20px;
        }
      }
      &-right {
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: $desktop-small) {
          justify-content: flex-start;
        }
      }

      &-text {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-weight: 400;
        & > b {
          color: black;
        }
      }
    }
  }
  &__title {
    padding: 0 50px 8px 50px;
    @media screen and (max-width: $mobile) {
      padding: 0 10px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 500;
    }
  }
  &-results {
    &-container {
      margin-top: 40px;
    }

    &-head {
      display: flex;
      justify-content: space-between;
      &-left {
        display: flex;
        flex-direction: column;
        &-count {
          color: #00000099;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    &-list {
      margin-top: 16px;
    }
  }
}
