@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.add-session-log-form {
  padding: 0px 50px 20px 50px;
  min-height: calc(100vh - 448px);

  @media screen and (max-width: $mobile) {
    padding-left: 0px;
    padding-right: 0px;
  }

  &-form-helper-text {
         color: red !important;
    }

  &-dynamic-group {
    border: 4px solid #e0e0e0;
    border-radius: 4px;
    margin: 20px 0 0 !important;
  }

  &-the-rest {
    margin-top: 12px !important;
  }
}
