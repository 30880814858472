@use './mixin' as *;
@use './vars' as *;

// Helper mixins
@mixin text($position) {
  .text-#{$position} {
    text-align: $position;
  }
}

$sizes: 8, 12, 16, 20, 24, 28, 32, 36, 40, 48, 56, 64, 72, 80, 100, 120;

@each $size in $sizes {
  @include spacing-helper($size, 1px);
}

// text position
$positions: center, right, left;

@each $position in $positions {
  @include text($position);
}

// flex display
.flex-center {
  display: flex;
  align-items: center;
}

$justifies: center, space-between, space-around, space-evenly, flex-start, flex-end;

@each $justify in $justifies {
  .justify-#{$justify} {
    @extend .flex-center;
    justify-content: $justify;
  }
}

// Font size
$fontFizes: 12, 14, 16, 18, 20, 22, 24, 32, 36, 40, 56;

@each $size in $fontFizes {
  .text-is-#{$size} {
    font-size: #{$size}px;
  }
}
// font weight
.fw-normal {
  font-weight: $font-weight-regular;
}

.fw-medium {
  font-weight: $font-weight-medium;
}

.fw-bold {
  font-weight: $font-weight-bold;
}

.text-gray {
  color: $color-text-gray;
}

@media (min-width: $tablet) {
  .modal .modal-content {
    width: auto;
  }
}
.modal .modal-content {
  @include mobileScreen {
    margin-left: 9px;
  }
}

.full-width {
  width: 100%;
}

.hide-on-mobile {
  @include mobileScreen {
    display: none;
  }
}

.hide-on-desktop {
  display: none;
  @include mobileScreen {
    display: block;
  }
}

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.has-text-secondary {
  color: $color-secondary;
}

.is-full-width {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.columns > .column {
  padding-top: 8px;
  padding-bottom: 8px;
}

.columns:not(:last-child) {
  margin-bottom: 0;
}

.is-status-active {
  color: $color-green;
}

.is-status-rejected {
  color: $color-red;
}

.is-status-pending {
  color: $color-warning;
}

.is-status-reviewing {
  color: $color-warning;
}

.is-status-setting {
  color: $color-primary;
}
.is-status-submitted {
  color: $color-black-300;
}
.form-section {
  background-color: $color-white;
  border-radius: 24px;
  padding: 32px;

  @include mobileScreen {
    border-radius: 12px;
    padding: 16px 16px 32px;
  }
}

.secondary-container {
  max-width: $secondary-container-width;
  width: 100%;
  margin: auto;
}
