@use '~src/scss/vars' as *;

.tiered-service-list {
  &-results {
    &-container {
    }
    &-header {
      font-size: 20px;
    }

    &-head {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;
      border-bottom: 1px solid #d6d6d6;

      &-left {
        display: flex;
        flex-direction: column;
        &-count {
          color: #00000099;
          font-size: 14px;
          font-weight: 400;
        }
      }
      @media screen and (max-width: $mobile) {
        flex-direction: column;

        &-left {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    &-list {
      margin-top: 16px;
    }
  }
}
