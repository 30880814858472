@use '~src/scss/vars' as *;

.tiered-service-form {
  padding: 35px $form-padding;

  @media screen and (max-width: $mobile) {
    padding: 16px;
  }

  &-dynamic-group {
    border: 4px solid #e0e0e0;
    border-radius: 4px;
    margin: 20px 0 0 !important;
    &-briefDescription {
      margin-top: 16px !important;
    }
  }

  &-the-rest {
    margin-top: 12px !important;
  }
}
