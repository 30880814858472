@use 'src/scss/vars' as *;

.tiered-service {
  &-page-container {
    padding-top: 20px;

    & .cmp-sticky-page-header {
      top: 64px;
    }
  }

  &-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px 20px;
    @media screen and (max-width: $mobile) {
      & h3 {
        display: none;
      }

      & button {
        width: 100%;
      }
    }
  }

  &-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
    & > span {
      font-weight: 700;
      font-size: 18px;
      color: #00000099;
    }

    &-full-height-button {
      height: 56px;
      @media screen and (max-width: $mobile) {
        height: auto;
      }
    }

    @media screen and (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 8px;
      & > button {
        align-self: flex-end;
        margin-top: 32px;
      }
    }
  }
}
