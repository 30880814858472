@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.submission-history {
  &-page-container {
    & .cmp-sticky-page-header {
      top: 64px;
    }
  }
  &-header {
    &-container {
      padding: 20px 32px;
      @include mobileScreen {
        padding: 8px 16px;
      }

      & h3 {
        @include mobileScreen {
          display: none;
        }
      }
    }
  }
  &-results {
    &-head {
      display: flex;
      justify-content: space-between;
      &-left {
        display: flex;
        flex-direction: column;
        &-count {
          color: #00000099;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    &-list {
      margin-top: 16px;
    }
  }
}
