@use '~src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.cmp-select {
  &-root {
    &-no-shrink {
      & legend {
        display: none;
      }

      // fix field outline is a little higher than other form control
      & fieldset {
        top: 0;
      }
    }
  }
  height: 56px;
  @include mobileScreen {
    height: 100%;
  }
  &-menu {
    max-height: 500px !important;
  }
  &-label {
    background-color: white !important;
    padding: 0 7px !important;
    margin-left: -5px;

    &-shrink {
      background-color: white;
    }
  }
  &-multiselect {
    &-value-container {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    &-chip {
      pointer-events: auto;
      border-radius: 4px !important;
      margin-top: 4px;
      margin-bottom: 4px;
      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    &-close-icon {
      position: absolute !important;
      top: -10px;
      right: 0;
    }

    &-clear-button {
      margin-right: 10px;
    }
  }
  &-dropdown-icon-hidden {
    display: none !important;
  }
}
