@use '~src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.add-session-dialog {
  &-mui-dialog-content {
    padding: 0 !important;
  }

  &-content {
    height: unset;
  }

  &-sticky-header {
    padding: 18px 20px;
  }

  &-selected-students {
    &-container {
      position: relative;
      width: 100%;
      padding-right: 30px;
      max-height: 180px;
      overflow-y: scroll;
    }

    &-chip {
      border-radius: 4px !important;
      padding: 7px 0px !important;
      height: auto !important;
      background-color: #512da8 !important;
      & > svg {
        color: rgba(255, 255, 255, 0.733);
      }
      color: white !important;
      font-weight: 700 !important;
      margin: 4px;
    }

    &-deselect-all {
      position: absolute !important;
      height: 30px;
      top: calc(50% - 15px);
      right: 0px;
    }
  }

  &-search-students {
    @include mobileScreen {
      padding: 8px 16px 0 16px;
    }

    &-title {
      font-weight: 500;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      display: block;
      margin-bottom: 8px;

      &.red {
        color: #f44336;
      }
    }

    &-inputs div button {
      height: 56px !important;
      @include mobileScreen {
        height: 40px !important;
      }
    }
  }

  &-students-table {
    width: 100%;
    & p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }
    & td:first-child {
      width: 40px;
      & svg {
        font-size: 18px;
      }
    }
    & td:not(:first-child) {
      padding: 8px 60px 8px 8px;
    }
    & td:last-child {
      padding: 8px;
    }

    & td:last-child {
      width: 30%;
      @media screen and (max-width: $desktop-small) {
        width: auto;
      }
    }

    & tr {
      border-bottom: 1px solid #ebebeb;

      &:hover {
        background-color: #ebebeb;
      }
    }
  }

  &-students-table-item-mobile {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ebebeb;

    & > button {
      margin-left: -12px;
    }

    &-content {
      padding: 8px;
      padding-top: 12px;

      // & > div:not(:last-child) {
      //   margin-bottom: 8px;
      // }
      & span {
        color: #00000099;
        font-size: 14px;
        font-weight: 400;
      }

      & span.name {
        font-weight: 500;
        color: #000000de;
        font-size: 17px;
      }

      & span:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &-pagination {
    margin-top: 24px;
  }
}

.textfield-checkbox {
  &-wrapper {
    position: relative;
  }
  &-icon {
    position: absolute !important;
    right: 5%;
    top: 10%;
  }
}

.support-types-select {
  &-wrapper {
    position: flex;
  }
  height: 10rem;
}
