@use '~src/scss/vars' as *;
@use '~src/scss/mixin' as *;

.layout {
  &-screen {
    display: flex;
    flex-direction: column;

    width: 100%;
  }

  &-content {
    padding-top: 4rem;
    display: flex;
    width: 100%;
    max-width: $max-container-width;
    margin: 0 auto;

    &.menu-show {
      max-width: $max-container-width-when-menu-open;
    }
  }

  &-main {
    width: 100%;
    margin-left: 0;

    // 1280 (max-width container) + 288 (sidebar-width) = 1568
    @media screen and (min-width: calc(#{$max-container-width} + #{$sidebar-width})) {
      margin-left: 0;
    }
    @media screen and (min-width: $widescreen), (max-width: $widescreen), (max-width: $desktop) {
      margin-left: $sidebar-width;
    }
    @media screen and (max-width: $tablet) {
      margin-left: $sidebar-mini-width;
    }
    @media screen and (max-width: $mobile) {
      margin-left: 0;
    }

    flex: 1;

    transition: $default-transition-med;

    &.menu-collapsed {
      margin-left: 0;
    }
  }
}
