.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;

  & {
    img {
      display: block;
      width: 120px;
      height: 100%;
      object-fit: contain;
    }
  }
}
