@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.session-logs {
  &-page-container {
    & .cmp-sticky-page-header {
      top: 64px;
    }
  }
  &-header {
    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mobileScreen {
        justify-content: center;
      }

      padding: 20px 32px;

      & h3 {
        @include mobileScreen {
          display: none;
        }
      }
    }
    &__buttonWrapper {
      & > *:not(:last-child) {
        margin-right: 16px;

        @include mobileScreen {
          margin-right: 0;
        }
      }
      &-add {
        @include mobileScreen {
          margin-top: 16px !important;
        }
      }
    }
  }
  & h6:first-child {
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
  }
  &-recent-accordion {
    &-details {
      cursor: pointer;
      & h5 {
        font-weight: 400;
      }

      &__item {
        padding: 16px 8px !important;
        position: relative;
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }

        @include mobileScreen {
          padding: 0 !important;
        }

        &-moreBtn {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      &__item:not(:first-child) {
        @include mobileScreen {
          padding-top: 8px !important;
        }
      }
      &__item:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        @include mobileScreen {
          padding-bottom: 8px !important;
        }
      }
    }
  }
  &-text-bold {
    font-weight: 500 !important;
    @include mobileScreen {
      font-weight: normal !important;
    }

    &.mobile {
      @include mobileScreen {
        font-weight: 500 !important;
      }
    }
  }
  &-text-color-gray {
    color: rgba(0, 0, 0, 0.6);
    @include mobileScreen {
      color: normal;
    }

    &.mobile {
      @include mobileScreen {
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &-mobile {
      @include mobileScreen {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
