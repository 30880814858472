@use '~src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.nav-bar {
  &,
  &-right,
  &-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-container {
    background-color: $color-primary;
    box-shadow: $shadow-smooth-hard-down;

    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  width: 100%;
  justify-content: space-between;
  color: $color-white;
  font-size: 14px;
  line-height: 24px;

  height: $navbar-height;
  padding-left: 12px;
  padding-right: 0.5rem;

  &-username {
    margin-right: 0.25rem;
    display: block;
    @include mobileScreen {
      display: none;
    }
  }

  &-app-name {
    &,
    &:hover {
      color: $color-white;
    }

    text-decoration: none !important;

    font-size: 20px;
    line-height: 30px;
    margin-left: 27px;

    &.mobile {
      font-size: 18px;
      margin-left: 0;
    }
  }

  &-menu-popover {
    top: 3.75rem !important;

    & .MuiMenuItem-root {
      padding: 16px !important;

      & .MuiListItemIcon-root {
        min-width: 36px !important;
      }
    }
  }
  &-icon {
    color: #fff;
    &.burger {
      font-size: 18px;
    }
    &.listItemIcon {
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
