@use '~src/scss/vars' as *;

.side-bar {
  &-drawer {
    @media screen and (max-width: $desktop-small) {
      width: $sidebar-mini-width;
      .side-bar-item-text {
        display: none;
      }
    }
    position: fixed;
    width: $sidebar-width;
    height: 100%;
    padding-top: 88px;
    box-shadow: $shadow-medium-right;
    background-color: $color-white;
    z-index: 998;
    transition: all 0.4s ease-in-out;
    opacity: 1;

    transition: $default-transition-med;
    transform: translateX(0);

    &.hide {
      transition: $default-transition-med;
      opacity: 0;
      transform: translateX(-100%);
    }
  }

  &-list-item {
    &:not(.active):hover {
      background-color: $color-primary;
    }
    & > a {
      display: flex;
      align-items: center;
      height: 56px;
      width: 100%;
      padding: 16px;
      text-decoration: none !important;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: #00000099;
      & > .side-bar-item-text {
        white-space: nowrap;
        padding-left: 16px;
        // position: fixed;
        // left: $sidebar-mini-width;
      }
      & > svg {
        font-size: 22px;
        // position: fixed;
      }
      & p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
      }
    }

    &:not(.active):hover,
    &.active {
      background-color: $color-primary;

      &.mobile {
        padding-top: 12px;
        padding-bottom: 12px;
        & > * {
          color: white;
        }

        & .side-bar-list-item-text {
          color: white;
        }
      }

      & > a {
        & > * {
          color: white;
        }
      }
    }
    &-icon > svg {
      font-size: 22px;
    }
    &-text {
      color: rgba(0, 0, 0, 0.6);
    }
  }
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;

    &__image {
      width: 60px;
      height: 60px;
      object-fit: contain;
      margin-right: 12px;
    }

    &__text {
      font-size: 20px;
    }
  }
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
