@use '~src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.user-preferences {
  padding-top: 16px;
  padding-bottom: 16px;

  margin: 16px;

  @include mobileScreen {
    margin: 8px;
  }

  &__subTitle {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.54);
  }
}
