@use '~src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.sl-student-info-banner {
  width: 100%;
  padding-bottom: 8px;

  color: white;

  & span {
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
  }

  &-summary h4,
  &-summary h5,
  &-details h4,
  &-details h5 {
    color: white;
  }
  &-summary h5,
  &-details h5 {
    font-weight: 400;
  }

  &-icon-expand {
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.2s ease-in-out;

    @include mobileScreen {
      top: 30px;
    }
  }
}
