:root {
  --primary: #673ab7;
  --light-primary: rgba(149, 117, 205, 0.5);
  --secondary: #009688;
  --danger: #ff0040;
  --success: #20d13b;
  --white: #ffffff;
  --black: #000000;
  --light-black: #656263;
  --background: #fff;
  --gray: #8d8d8d;
  --gray-light: #9f9f9f;
  --link: #1562f8;
  --link-dark: #2a5cb1;
}

// Basic Colors
$color-white: #ffffff;
$color-black: var(--black);
$color-light-black: var(--light-black);
$color-green: #1da832;
$color-red: #fc0000;
$color-warning: #ca9d00;

$color-black-500: #231f20;
$color-black-400: #444141;
$color-black-300: #656263;
$color-black-200: #918f90;
$color-black-100: #bdbcbc;
$color-black-50: #e5e4e4;

$color-red-light: #ffeeee;
$color-primary-red-light: #eefaff;

// Style color
$color-link: var(--link);
$color-link-dark: var(--link-dark);
$color-text: $color-black-500;
$color-text-gray: var(--gray);
$color-text-inactive: var(--gray-light);
$color-tab-panel: var(--light-black);

$color-header: $color-black-500;
$color-border: #e5e4e4;
$color-placeholder: #9e9e9e;

$color-primary: var(--primary);
$color-primary-background: var(--background);
$color-primary-light: var(--light-primary);
$color-secondary: var(--secondary);
$color-danger: var(--danger);
$color-success: var(--success);

$green-gradient-transparent: #22767c95;
$orange-gradient-transparent: #8a722b95;
$red-gradient-transparent: #c6000095;
$option-gradient-transparent: #29287020;

// Font
$font-primary: 'Roboto';
$font-secondary: 'Lato';
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;

// Size
$body-size: 16px;
$color-text-size: 14px;

// button
$button-small: 32px;
$button-normal: 42px;
$button-medium: 48px;

// input
$input-small: 32px;
$input-normal: 40px;
$input-medium: 48px;

$input-padding: 0 12px;
$input-boder-radius: 4px;

// border
$default-border: 1px solid $color-border;
$default-border-primary: 2px solid $color-primary;
$default-space: 12px;
$default-transition: all 0.2s ease-in-out;
$default-transition-med: all 0.4s ease-in-out;
$default-linear-trans: all 0.2s linear;
$default-linear-trans-med: all 0.4s linear;
$default-border-radius: 4px;

$navbar-height: 4rem;
$footer-height: 72px;

$sidebar-width: 288px;
$sidebar-mini-width: 56px;

// reponsive checkpoint
$widescreen: 1440px;
$desktop: 1280px;
$tablet: 960px;
$mobile: 600px;
$desktop-small: 960px;
$max-container-width: 1280px;
$max-container-width-when-menu-open: 1700px;
$form-padding: 80px;

$secondary-container-width: 1100px;

$default-shadow: 0px 4px 20px rgba(224, 224, 224, 0.5);

$shadow-smooth-hard-down: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
  0px 3px 1px -2px rgba(0, 0, 0, 0.2);
$shadow-medium-right: 1px 0px 4px 0px rgb(0 0 0 / 15%);
$shadow-medium-down: 0px 1px 0px 0px rgba(0 0 0 / 15%);
$shadow-medium-up: 0px -1px 4px 0px rgba(0 0 0 / 15%);

$focus-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
$error-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(218, 92, 92, 0.6);

$sidebar-bg-color: #001529;
$icon-bg-color: #001529;
$submenu-bg-color: #000c17;
$submenu-bg-color-collapsed: #000c17;

$page-content-padding-v: 32px;
$page-content-padding-h: 32px;
$page-content-padding-v-mobile: 16px;
$page-content-padding-h-mobile: 20px;
