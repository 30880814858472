@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.ctn-uam {
  color: $color-black-300;
  background-color: $color-white;
  min-height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  & {
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      margin: 0 auto;
      background-color: $color-primary-light;
      width: 100%;

      padding: 63px 0 25px 0;
    }

    &__body {
      background-color: $color-white;
      width: 600px;
      border-radius: 4px;

      align-items: center;

      text-align: center;

      padding: 46px 75px;

      @media screen and (max-width: $mobile) {
        width: 100%;
        padding: 46px 16px;
      }
    }

    &__logoImage {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }

    &__title {
      font-weight: 700;
      line-height: 60px;
      font-size: 36px;
    }

    &__form {
      display: flex;
      flex-direction: column;
      width: 100%;

      & .MuiTextField-root:first-child {
        margin-bottom: 32px;
      }

      & .MuiFormLabel-root.Mui-focused {
        color: $color-primary;
      }

      & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $color-primary;
      }
    }

    &__link {
      font-size: 14px;
      margin-left: auto;
    }

    &__footer {
      margin-top: auto;
    }

    &__verify-code {
      justify-content: flex-start;
    }
  }
}

.cmp-nav-link {
  color: $color-link;
}
