.search-dropdown-box {
  &-input-container {
    position: relative;
  }
  &-empty-text {
    position: absolute;
    left: 14px;
    top: calc(50% - 11px);
    visibility: hidden;
    font-size: 1rem;
    &.show {
      visibility: visible;
    }
  }
}
