@use '~src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.submission-history-detail {
  &-dialog {
    margin-top: 32px !important;
    margin-bottom: 32px !important;

    @include mobileScreen {
      margin: 32px 16px !important;
    }

    & .MuiDialogContent-root {
      padding: 0 !important;
    }

    & .MuiDialog-paper {
      max-width: 800px !important;
      border-radius: 4px !important;
    }
    &-title {
      border-bottom: 1px solid #ebebeb;
    }
    &-actions-container {
      position: absolute !important;
      right: 53px;
      top: 15px;
      & svg {
        font-size: 18px;
      }
    }
    &-icon {
      position: absolute !important;
      right: 5px;
      top: 5px;
    }
  }

  &-session-info {
    border-bottom: 1px solid #efefef;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }

    &.omit-margin {
      margin-bottom: 0;
    }

    &.no-title {
      .service-detail-session-info-row:first-child {
        margin-top: 0;
      }
    }

    &-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      margin-bottom: 12px;

      & > div {
        display: flex;
        align-items: center;
      }
      & svg {
        color: $color-primary;
        margin-right: 10px;
        font-size: 20px;
      }
      &-collapse-button {
        margin-right: -12px !important;
        & svg {
          color: #0000008a;
          margin: 0;
        }
      }

      @include mobileScreen {
        margin-bottom: 0px;
      }
    }
    &-row {
      display: flex;
      margin: 4px 30px;
      // align-items: center;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 12px;
      }
    }
    &-label {
      flex: 2;
      font-weight: 500;
      margin-right: 8px;
    }
    &-content {
      flex: 4;
      color: rgba(0, 0, 0, 0.6);
    }

    &-break {
      height: 1px;
      background-color: #efefef;
    }

    @media screen and (max-width: $mobile) {
      &-row {
        margin-left: 0;
        margin-right: 0;

        &.vertical {
          flex-direction: column;
          align-items: stretch;
        }
        &.multiple-mobile {
          flex-direction: column;
        }
      }
      &-label {
        flex: 2;
        font-weight: 500;
      }
      &-content {
        flex: 4;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &-audit-log-item-container {
      & > button {
        margin-left: 8px;
        transform: translateY(-2px);
      }

      & .time {
        margin-left: 8px;
      }
    }
  }
}
