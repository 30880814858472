@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.session-log-list {
  margin-top: 16px;

  &-head {
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      flex-direction: column;
      &-count {
        color: #00000099;
        font-size: 14px;
        font-weight: 400;
      }
    }
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
