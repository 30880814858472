@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.search-session-logs {
  &-page-container {
    & .cmp-sticky-page-header {
      top: 64px;
    }
  }
  &-header {
    &-container {
      padding: 16px 32px;

      & h3 {
        @include mobileScreen {
          display: none;
        }
      }
      @include mobileScreen {
        padding: 8px 16px;
      }
    }
  }
  &__title {
    color: rgba(0, 0, 0, 0.6);
  }
  &-results {
    &-container {
      margin-top: 16px;
    }

    &-head {
      display: flex;
      justify-content: space-between;
      &-left {
        display: flex;
        flex-direction: column;
        &-count {
          color: #00000099;
          font-size: 14px;
          font-weight: 400;
        }
      }
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}
