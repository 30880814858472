@use '~src/scss/vars' as *;

.cmp-dialog {
  &-title {
    border-bottom: 1px solid #ebebeb;
  }

  &-close-icon {
    position: absolute !important;
    right: 5px;
    top: 5px;
  }

  &-footer {
    padding: 8px 24px !important;
    border-top: 1px solid #ebebeb;
  }
}
