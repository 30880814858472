@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.scheduler {
  &-page-container {
    & .cmp-sticky-page-header {
      top: 64px;
    }
  }
  &__title {
    display: block;
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }
}

.search-full-height-button {
  height: 56px;
}

.button {
  &__outlined__danger {
    color: #e31b0c !important;
    border: 1px solid #bdbdbd !important;
  }

  &__iconButton-disable-padding {
    padding: 0px !important;
  }
  &__iconButton-padding-8 {
    padding: 8px !important;
  }
  &__iconButton-padding-8-4 {
    padding: 8px 4px !important;
  }

  &__add__team {
    margin-bottom: 8px !important;

    @media screen and (max-width: $mobile) {
      margin-bottom: 0 !important;
    }
  }
}

.serviceInactive {
  color: #fff;
  padding: 4px 6px;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 64px;
  text-align: center;
}

.scheduler-provider {
  & {
    &__search {
      &-full-height-button {
        height: 56px;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      &-header {
        & div:first-child {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @media screen and (max-width: $mobile) {
            flex-direction: column;
          }

          & div:first-child {
            display: block;

            p {
              color: rgba(0, 0, 0, 0.6);
            }
            @media screen and (max-width: $mobile) {
              h6,
              p {
                margin-top: 8px;
                color: #673ab7;
              }
            }
          }
        }
      }
    }
    &__switch {
      display: flex;
      flex-direction: row;
      align-items: center;

      & {
        p {
          color: rgba(0, 0, 0, 0.38);
          font-size: 16px;
        }
      }
      @media screen and (max-width: $mobile) {
        margin-top: 16px;
        justify-content: flex-end;
      }
    }
    &__numberItem {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &__services {
      width: 100%;
    }
    &__serviceStudent {
      width: 100%;
      padding: 16px;

      display: flex;
      flex-direction: column;

      border-radius: 4px;
      box-shadow: 0px 0px 0px 1px #e0e0e0;
      border: 1px solid rgba(0, 0, 0, 0.01);

      @media screen and (max-width: $mobile) {
        padding: 8px 8px;
      }
    }
    &__studentInfo {
      // margin-bottom: 16px !important;

      @media screen and (max-width: $mobile) {
        // margin-bottom: 8px !important;

        & div:not(:first-child) {
          h5 {
            font-weight: 400;
          }
        }
      }

      &-userInfo {
        width: initial;
        @media screen and (max-width: $mobile) {
        }
      }
    }
    &__service {
      margin: 8px 0;
      @include mobileScreen {
        margin: 0;
      }
      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        margin-right: 32px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      & > div {
        display: flex;
        flex-direction: row;
      }

      @media screen and (max-width: $mobile) {
        border-top: 1px solid rgba(0, 0, 0, 0.1);

        p {
          margin-right: 0;
        }

        & > div {
          padding-top: 8px;
        }

        & > div:not(:last-child) {
          flex-direction: column;

          p {
            margin-bottom: 8px;
          }
        }
      }
      &-date {
        grid-area: service-date;
      }

      &-frequency {
        grid-area: service-frequency;
      }
    }

    &__pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.student {
  &__studentInfo {
    width: 100%;
    margin-bottom: 10px;

    & div:last-child {
      display: flex;
      flex-direction: row;
      margin-top: 4px;

      h5 {
        margin-right: 16px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
      }
    }
  }

  &__servicesList {
    width: 100%;
  }
  &__accordionSummary {
    & p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 400;
    }
  }
  &__serviceEsy {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: $mobile) {
      & h5:first-child {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
      }
    }
  }

  &__accordionDetails {
    width: 100%;
  }
  &__provider {
    width: 100%;

    & div:first-child {
      & p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
      }

      & > div {
        display: flex;
      }

      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}
