@use './mixin' as *;
@use './vars' as *;

.Toastify__toast {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 16px !important;
  font-weight: normal;
  font-family: $font-primary;
  backdrop-filter: blur(5px);
  padding: 16px !important;
}
.Toastify__toast--success {
  background: $green-gradient-transparent !important;
}
.Toastify__toast--info {
  background: $green-gradient-transparent !important;
}
.Toastify__toast--warning {
  background: $orange-gradient-transparent !important;
}
.Toastify__toast--error {
  background: $red-gradient-transparent !important;
}

.Toastify__toast-body {
  white-space: pre-line !important;
}
