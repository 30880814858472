@use '~src/scss/vars' as *;

.edit-tiered-service {
  &-page-container {
    padding: 0;
  }
  &-header {
    padding-bottom: 20px !important;

    @media screen and (max-width: $mobile) {
      display: none;
    }
  }
}
