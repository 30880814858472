@use '~src/scss/vars' as *;

.add-tiered-service {
  &-page-container {
    padding: 0;
  }

  &-sticky-header {
    top: 64px;
  }

  &-header {
    @media screen and (max-width: $mobile) {
      display: none;
    }

    &-status {
      padding: 10px 32px;
      @media screen and (max-width: $mobile) {
        padding: 10px 16px;
      }

      &-left {
        & > * {
          margin-right: 20px;
        }

        @media screen and (max-width: $mobile) {
          display: flex;
          flex-direction: column !important;
          align-items: flex-start;
        }
      }

      &-right {
        display: flex;
        justify-content: flex-end;
        // @media screen and (max-width: $desktop-small) {
        //   justify-content: flex-start;
        // }
      }

      &-text {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-weight: 500;
        & > b {
          color: black;
        }
      }

      &-button {
        @media screen and (max-width: $mobile) {
          display: block;
        }
      }

      &-done-list {
        min-width: 275px !important;
        padding: 4px 0 !important;
      }
    }
  }
}
