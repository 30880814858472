@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.session-log-item {
  padding: 24px;
  box-shadow: 0px 1px 10px 0px #0000001a;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  transition: all 0.2s ease-in-out;

  @include mobileScreen {
    padding: 8px;
  }

  position: relative;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &-time {
    & > * {
      text-align: center;
      color: $color-primary;
    }
    background-color: rgba(103, 58, 183, 0.08);

    &-absent {
      background-color: rgba(244, 67, 54, 0.08);

      & > * {
        color: $color-red !important;
      }
    }

    padding: 16px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & p {
      font-size: 16px;
    }
  }
  & h5 {
    font-weight: 400;
  }

  &__moreBtn {
    position: absolute;
    top: 4px;
    right: 4px;
    // z-index: 99;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      padding: 15px;
    }
  }
}
