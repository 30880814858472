@use '~src/scss/vars' as *;

.submission-history {
  &-back-button {
    font-size: 13px;
    margin-left: 32px !important;
  }
  &-page-size {
    align-self: flex-end;
  }
  
  @media screen and (max-width: $mobile) {
    &-header {
      display: none;
    }
    &-back-button {
      margin: 20px 16px 0 !important;
    }
  }
}
