@use '~src/scss/vars' as *;

.cmp-mui-textarea {
  border: 2px solid #cbcbcb;
  border-radius: 4px;
  &:focus,
  &:hover {
    border-color: $color-primary;
  }
}
