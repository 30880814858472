@use '~src/scss/vars' as *;

.tiered-service-item {
  &-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-bottom: 16px;

    border-radius: 4px;
    box-shadow: 0px 0px 0px 1px #e0e0e0;

    &-clickable {
      text-align: left;
      padding: 16px !important;
      background-color: #fff;
      cursor: pointer;

      @media screen and (max-width: $mobile) {
        padding: 8px !important;
      }
      text-transform: none !important;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      transition: all 0.2s ease-in-out;
    }

    &-action-button-trigger {
      z-index: 10;
      position: absolute !important;
      right: 0px;
      top: -4px;
      font-size: 20px !important;
    }
  }
  &-service-info {
    &-student {
      display: flex;
      flex-direction: column;
    }
    & span:first-child {
      font-weight: 500;
      font-size: 14px;
      color: #000000de;
    }
    & span:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      color: #00000099;
    }
    @media screen and (max-width: $mobile) {
      & span:nth-child(2) {
        margin-top: unset;
      }
      & span:first-child:not(.student-name) {
        font-weight: 400;
      }

      &-mobile {
        & > div {
          & > span:nth-child(2) {
            font-size: 14px;
            color: #000000de;
            margin-left: 24px;
          }
        }
      }
    }
  }

  &-actions {
    & svg {
      color: #0000008a;
    }
    & .danger {
      color: #e31b0c;
    }
    &-menu-item {
      padding-left: 4px !important;
      padding-right: 16px !important;
    }
    & button {
      margin-top: -8px;
    }
  }
}
