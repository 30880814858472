@use '~src/scss/vars' as *;

.student-info-banner {
  padding: 16px 0;
  @media screen and (max-width: $mobile) {
    padding: 0;
  }
  &-background {
    background-color: $color-primary;
    padding: 0 $form-padding;
    @media screen and (max-width: $mobile) {
      margin: 16px 16px 4px;
      border-radius: 4px;
      padding: 8px;
    }
  }
  color: white;
  & span.student-info-banner-label {
    font-weight: 700;
  }
  & span {
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
  }

  @media screen and (max-width: $mobile) {
    & span {
      display: inline-block;
      margin-bottom: 0px;
      font-weight: 400;

      &:first-child {
        display: none;
      }

      &.show-mobile {
        display: inline-block;
      }

      &.name {
        font-size: 18px;
        font-weight: 700;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
