@use '~src/scss/vars' as *;

.cmp-sticky-page-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;

  background: #fff;
  & > h3 {
    padding: 20px 32px 0 32px;
  }

  @media screen and (max-width: $mobile) {
    & > h3 {
      padding: 20px 20px 0 20px;
    }
  }

  box-shadow: $shadow-medium-down;
}
