@use 'src/scss/vars' as *;

.page {
  &-main {
    height: calc(100vh - #{$navbar-height});

    // @media screen and (max-width: $mobile) {
    display: unset;
    // }

    &.has-footer {
      height: calc(100vh - #{$navbar-height} - #{$footer-height});
    }
  }

  &-content {
    padding: $page-content-padding-v $page-content-padding-h;
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and (max-width: $mobile) {
      padding: $page-content-padding-v-mobile $page-content-padding-h-mobile;
    }

    &-loading {
      position: absolute;
      background-color: #ffffffa8;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 97;
    }
    &-loading-indicator {
      position: fixed;
      top: 50%;
      left: 50%;
    }
  }

  &-footer {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: $color-white;
    height: $footer-height;
    box-shadow: $shadow-medium-up;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 33px;
    & > *:not(:last-child) {
      margin-right: 16px;
    }
  }
}
