@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.submission-history-item {
  padding: 24px;
  box-shadow: 0px 1px 10px 0px #0000001a;

  @include mobileScreen {
    padding: 16px;
  }

  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  transition: all 0.2s ease-in-out;

  position: relative;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & h5 {
    font-weight: 500;
    @include mobileScreen {
      font-weight: 400;
      color: rgba($color: #000, $alpha: 0.6);
    }
  }
  & h6 {
    font-weight: 400;
    font-size: 14px;
    color: rgba($color: #000, $alpha: 0.6);
  }

  &-name-highlight-mobile {
    @include mobileScreen {
      font-weight: 500 !important;
      color: rgba($color: #000, $alpha: 1) !important;
    }
  }

  &__moreBtn {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 999;
  }

  &-text-bold-mobile {
    @include mobileScreen {
      font-weight: 500 !important;
    }
  }

  & .mb-8.mobile {
    @include mobileScreen {
      margin-bottom: 0;
    }
  }
}
