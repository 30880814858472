@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.add-session-logs {
  &-page-container {
    & .cmp-sticky-page-header {
      top: 64px;
    }
  }
  &-header {
    &-container {
      padding: 20px 32px 0px 32px;

      @include mobileScreen {
        padding: 16px !important;
      }

      & h3 {
        @include mobileScreen {
          display: none;
        }
      }
    }
    &-status {
      padding: 10px 0px !important;
      @media screen and (max-width: $mobile) {
        padding: 0px 16px !important;
      }

      &-left {
        & > * {
          margin-right: 20px;
        }

        @media screen and (max-width: $mobile) {
          display: flex;
          flex-direction: column !important;
          align-items: flex-start;
        }
      }

      &-right {
        display: flex;
        justify-content: flex-end !important;
      }

      &-text {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-weight: 500;
        & > b {
          color: black;
        }
      }

      &-button {
        @media screen and (max-width: $mobile) {
          display: block;
        }
      }

      &-done-list {
        min-width: 275px !important;
        padding: 4px 0 !important;
      }
    }
  }

  &-results {
    &-container {
      margin-top: 40px;
    }

    &-head {
      display: flex;
      justify-content: space-between;
      &-left {
        display: flex;
        flex-direction: column;
        &-count {
          color: #00000099;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    &-list {
      margin-top: 16px;
    }
  }
}
