@use 'src/scss/vars' as *;
@use 'src/scss/mixin' as *;

.session-log-template-form {
  padding: 20px 50px;

  @media screen and (max-width: $mobile) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &-dynamic-group {
    border: 4px solid #e0e0e0;
    border-radius: 4px;
    margin: 20px 0 0 !important;
  }

  &-the-rest {
    margin-top: 12px !important;
  }
}
