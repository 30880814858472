@use '~src/scss/vars' as *;

.add-service-dialog {
  &-mui-dialog-content {
    padding: 0 !important;
  }

  &-content {
    height: unset;
    &-page-content {
      min-height: 225px;
    }
  }

  &-sticky-header {
    padding: 18px 20px;
  }

  &-selected-students {
    &-container {
      position: relative;
      width: 100%;
      padding-right: 30px;
      max-height: 180px;
      overflow-y: scroll;
    }

    &-chip {
      border-radius: 4px !important;
      padding: 7px 0px !important;
      height: auto !important;
      background-color: $color-primary !important;
      & > svg {
        color: rgba(255, 255, 255, 0.733);
      }
      color: white !important;
      font-weight: 700 !important;
      margin: 4px;
    }

    &-deselect-all {
      position: absolute !important;
      height: 30px;
      top: calc(50% - 15px);
      right: 0px;
    }
  }

  &-search-students {
    &-title {
      font-weight: 500;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      display: block;
      margin-bottom: 8px;
    }

    &-inputs {
      display: flex;

      justify-content: stretch;

      & > *:not(:last-child) {
        margin-right: 14px;
      }
      & > div {
        flex: 1;
      }

      @media screen and (max-width: $mobile) {
        flex-direction: column;
        & > *:not(:first-child) {
          margin-top: 18px !important;
        }

        & > button {
          height: 56px !important;
        }
      }
    }
  }

  &-students-table {
    width: 100%;
    & p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }
    & td:first-child {
      width: 40px;
      & svg {
        font-size: 18px;
      }
    }
    & td:not(:first-child) {
      padding: 8px 60px 8px 8px;
    }

    & td:last-child {
      width: 20%;
      @media screen and (max-width: $desktop-small) {
        width: auto;
      }
    }

    & tr {
      border-bottom: 1px solid #ebebeb;

      &:hover {
        background-color: #ebebeb;
      }
    }
  }

  &-students-table-item-mobile {
    display: flex;
    // align-items: flex-start;
    border-bottom: 1px solid #ebebeb;

    & > button {
      margin-left: -12px;
    }

    &-content {
      padding: 8px;
      padding-top: 12px;

      & div.student-id {
        & p.name {
          font-weight: 400;
          margin-right: 8px;
          transform: translateY(-4px);
        }
        display: flex;
      }
      & span {
        color: #00000099;
        font-size: 14px;
        font-weight: 400;
        margin-right: 16px;
      }
    }
  }

  &-pagination {
    margin-top: 24px;
  }

  &-no-records {
    font-style: italic;
  }
}
